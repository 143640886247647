//-----navbar-----//
$baseColor: #0e3b38;
$secondaryColor: #36908a;
$topNavTextColor: #ffffff;
$bottomNavtextColor: white;
$bottomNavInnertextColor: black;
$bottomNavInnerTextColor: black;
$navElementBorderColor: #36908a;
$navElementHoverColor: #0e3b38;
$mobileNavElementBackground: #fae1cb;
$mobileNavInnerElementBackground: #fef4eb;

//-----slider-----//
$baseDotColor: #0e3b38;
$activeDotColor: #36908a;

//------home/basic page button-----------//
$buttonBackgroundColor: #0e3b38;
$buttonHoverBackgroundColor: #36908a;
$buttonHoverTextColor: #181818;
$buttonTextColor: #ffffff;

//breadcrumb
$breadcrumbTextColor: #0e3b38;
$headingsColor: #36908a;

//-----home page variables ----//
$servicesBackground: rgb(54, 144, 138, 0.1);
$happeningsBackground: rgb(54, 144, 138, 0.1);
$headingTextColor: #0e3b38;
$newsBarColor: #0e3b38;

//-----footer varibales----//
$footerTopBackground: #36908a;
$footerMiddleBackground: #0e3b38;
$footerBottomBackground: #062221;
