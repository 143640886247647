.sd-about-one {
  font-family: "Proxima Nova Alt", sans-serif;
  padding: 60px 60px 0px 60px;

  .sd-about-one-top {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 30px;
      color: #991403;
      font-weight: 400;
      text-align: center;
      padding: 30px 60px;
    }
    p {
      text-align: center;
      font-size: 24px;
      color: #181818;
    }
  }
  .sd-about-one-bottom {
    .sd-about-one-bottom-top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        font-size: 30px;
        color: #991403;
        padding-bottom: 10px;
        letter-spacing: 0;
        font-weight: 400;
        span {
          font-weight: 600;
        }
      }
      img {
        height: 20px;
      }
      padding: 0px 40px 30px 30px;
    }
    h3 {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      span {
        font-weight: 400;
      }
    }
    .sd-j {
      img {
        height: 560px;
      }
      display: flex;
      justify-content: center;
    }
    .sd-voilet {
      color: #991403;
    }
    .sd-list-order {
      padding-top: 10px;
      padding-bottom: 60px;
      list-style-type: decimal;

      li {
        color: #181818;

        font-size: 16px;
        line-height: 24px;
        padding-left: 10px;
      }
    }
    .sd-about-one-bottom-bottom {
      display: flex;
      justify-content: center;
      .sd-about-seva-div {
        margin: 10px;
        overflow: hidden;
        padding: 18px 18px 0;
        margin-bottom: 30px;
        padding-bottom: 10px;
        border: 1px solid #bfbfbf;
        border-radius: 4px;
        width: 350px;
        // height: 380px;
        display: flex;
        flex-direction: column;
        .sd-top-image {
          padding: 10px 25px 25px 25px;
          border-bottom: 2px solid #bfbfbf;
          display: flex;
          justify-content: center;
          width: 92%;
          align-items: center;
          img {
            width: 55px;
            height: 60px;
          }
          .sd-spcl {
            width: 65px;
            height: 60px;
          }
          h4 {
            text-align: left;
            color: #181818;
            font-weight: 600;
            font-size: 22px;
            line-height: 20px;
            padding-left: 15px;
            margin-bottom: 0px;
          }
        }
        .sd-about-list {
          ol {
            padding: 10px 10px 10px 0px;
            li {
              padding: 2px;
              color: #991403;
              word-break: break-word;
              cursor: pointer;
            }
            li::marker {
              color: #991403;
            }
          }
        }
      }
    }
  }
  .sd-bb {
    border-bottom: 1px solid #efefef;
  }
}
.w-300 {
  width: 300px !important;
}
.sd-about-temple-history {
  font-family: "Proxima Nova Alt", sans-serif;
  padding: 90px 60px 0px 60px;
  margin-bottom: 60px;
  display: flex;
  .sd-about-temple-history-left {
    width: 300px;
    padding: 30px;
    padding-bottom: 30px;
    background-color: #efefef;
    border-radius: 3px;
    max-height: 700px;
    overflow-y: scroll;
    position: sticky;
    position: -webkit-sticky; /* Safari */

    top: 15px;
    &::-webkit-scrollbar {
      display: none;
    }

    .sd-active {
      color: #36908a;
      &::marker {
        color: #36908a;
      }
    }
    ol {
      list-style-type: disc;

      padding: 0px 10px 0px 20px;
      li {
        padding: 2px;
        padding-bottom: 10px;
        word-break: break-word;
        font-weight: 600;
        font-size: 15px;
        cursor: pointer;
        color: #991403;
        img {
          float: right;
        }
      }
      li::marker {
        color: #a39d98;
      }
      .main {
        display: flex;
        justify-content: space-between;
        padding: 5px 2px 5px 2px;
        color: #181818;
        font-weight: 600;
        cursor: pointer;
      }
      .drop1 {
        margin-top: -5px;
        margin-bottom: 5px;
        padding-left: 0px 0px 10px 0px;
        .sd-active {
          color: #991403 !important;
        }
        .drop2 {
          padding-bottom: 5px;
          .sd-active {
            color: #991403 !important;
          }
          div {
            margin-left: 5px;
            padding: 3px 0px;
            margin-right: 5px;
            color: #181818;

            font-weight: 400;
            // background: bisque;
            text-align: left;
            width: 100%;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
              height: 18px;
              margin-right: 20px;
            }
          }

          .block {
            display: block;
          }
          .none {
            display: none;
          }
        }
        .drop3 {
          padding-left: 0px;
          padding-bottom: 5px;
          .sd-active {
            color: #991403 !important;
          }
          div {
            margin-left: 5px;
            margin-right: 5px;
            padding: 3px 0px;
            color: #181818;

            font-weight: 400;
            // background: bisque;
            text-align: left;
            width: 100%;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
              height: 18px;
              margin-right: 20px;
            }
          }

          .block {
            display: block;
          }
          .none {
            display: none;
          }
        }
        .drop4 {
          padding-left: 0px;
          padding-bottom: 5px;

          div {
            margin-left: 5px;
            margin-right: 5px;
            margin-right: 15px;
            padding: 3px 0px;
            color: #181818;

            font-weight: 400;
            // background: bisque;
            text-align: left;
            width: 100%;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
              height: 18px;
              margin-right: 20px;
            }
          }
          .sd-active {
            color: #991403 !important;
          }

          .block {
            display: block;
          }
          .none {
            display: none;
          }
        }
        .main {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
        }
        .drop5 {
          padding-left: 0px;
          padding-bottom: 5px;
          .sd-active {
            color: #991403 !important;
          }
          div {
            margin-left: 5px;
            margin-right: 5px;
            margin-right: 15px;
            padding: 3px 0px;
            color: #181818;

            font-weight: 400;
            // background: bisque;
            text-align: left;
            width: 100%;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
              height: 18px;
              margin-right: 20px;
            }
          }

          .block {
            display: block;
          }
          .none {
            display: none;
          }
        }
        .block {
          display: block;
        }
        .none {
          display: none;
        }

        .sd-active {
          color: #991403;
          &::marker {
            color: #991403;
          }
        }
      }
      .drop6 {
        margin-top: 0px;
        margin-bottom: 5px;
        padding-left: 0px 0px 10px 0px;
        .sd-active {
          color: #991403 !important;
        }
        div {
          margin: 5px 0px 5px 5px;
          color: #181818;

          font-weight: 400;
          // background: bisque;
          text-align: left;
          width: 100%;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            height: 18px;
            margin-right: 20px;
          }
        }
        .block {
          display: block;
        }
        .none {
          display: none;
        }
      }
      .drop-about-one {
        margin-top: -5px;
        padding-bottom: 8px;
        .sd-active {
          color: #991403 !important;
        }
        div {
          margin-left: 5px;
          margin-right: 5px;
          padding: 3px 0px;
          color: #181818;

          font-weight: 400;
          // background: bisque;
          text-align: left;
          width: 100%;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            height: 18px;
            margin-right: 20px;
          }
        }

        .block {
          display: block;
        }
        .none {
          display: none;
        }
      }
      .drop-about-two {
        margin-top: -5px;

        padding-left: 0px;
        padding-bottom: 8px;
        .sd-active {
          color: #991403 !important;
        }
        div {
          margin-left: 5px;
          margin-right: 5px;
          padding: 3px 0px;
          color: #181818;

          font-weight: 400;
          // background: bisque;
          text-align: left;
          width: 100%;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            height: 18px;
            margin-right: 20px;
          }
        }

        .block {
          display: block;
        }
        .none {
          display: none;
        }
      }

      .block {
        display: block;
      }
      .none {
        display: none;
      }
    }
  }
  .sd-about-temple-history-left {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .sd-about-temple-history-right {
    width: 80%;
    .sd-about-temple-history-right-top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        font-size: 30px;
        color: #991403;
        padding-bottom: 10px;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        span {
          font-weight: 600;
        }
      }
      img {
        height: 10px;
      }
      padding: 0px 40px 30px 30px;
    }
    .sd-about-temple-history-right-bottom {
      padding: 10px 60px 60px 60px;
      .sd-athrb-top {
        // display: flex;
        .sd-imgs {
          width: 300px;
          margin-right: 40px;
          // margin-bottom: 15px;
          // margin-top: 5px;
          border-radius: 6px;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          color: #181818;
          text-align: left;
          .trust-board-wrapper {
            padding-bottom: 10px;
            .flexed {
              display: flex;
              flex-wrap: wrap;
              margin-top: 50px;
              justify-content: space-between;
              .trust-board {
                text-align: center;
                width: 33%;
                margin-bottom: 20px;
                min-width: 190px;
              }
            }
          }
        }
      }
      .sd-athrb-bottom {
        padding-top: 20px;
        font-size: 20px;
      }
    }
  }
}
.sd-p-60 {
  padding-bottom: 60px;
}
.trust-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
    th,
    td {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    th {
      background-color: #f2f2f2;
    }
  }
}

@media screen and (min-width: 1600px) {
  .sd-about-one {
    padding-top: 90px;
    .sd-about-one-bottom {
      .sd-about-one-bottom-bottom {
        justify-content: center;
        .sd-about-seva-div {
          width: 455px !important;
          margin: 10px;

          h5 {
            padding-bottom: 25px;
          }
          .sd-timings {
            width: 455px !important;
          }
          div {
            .sd-free {
              font-size: 17px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) and (min-width: 920px) {
  .sd-about-one {
    .sd-about-one-bottom {
      .sd-about-one-bottom-bottom {
        justify-content: center;
        .sd-about-seva-div {
          width: 255px !important;
          margin: 10px;

          h5 {
            padding-bottom: 25px;
          }
          .sd-timings {
            width: 255px !important;
          }
          div {
            .sd-free {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .sd-about-temple-history {
    .sd-about-temple-history-left {
      ol {
        li {
          color: #181818;
        }
      }
    }
    .sd-about-temple-history-right {
      .sd-about-temple-history-right-bottom {
        .sd-athrb-top {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          p {
            font-size: 18px;
          }
          .sd-imgs {
            width: 300px;
            margin: 0px 0px 20px 0px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 920px) and (min-width: 600px) {
  .w-300 {
    height: 100% !important;
  }
  .sd-about-one {
    .sd-about-one-top {
      padding: 0px;
      h3 {
        font-size: 24px;
        padding: 30px 0px;
      }
      p {
        font-size: 22px;
      }
    }
    .sd-about-one-bottom {
      .sd-about-one-bottom-bottom {
        flex-wrap: wrap;
        justify-content: center;
        .sd-about-seva-div {
          width: 400px;

          h5 {
            padding-bottom: 25px;
          }
          .sd-timings {
            width: 400px;
          }
          div {
            .sd-free {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .sd-about-temple-history {
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    .sd-about-temple-history-left {
      ol {
        li {
          color: #181818;

          font-size: 16px;
        }
      }
    }
    .sd-about-temple-history-right {
      width: 70%;
      .sd-about-temple-history-right-top {
        h1 {
          text-align: center !important;
        }
        img {
          height: 20px;
        }
      }
      .sd-about-temple-history-right-bottom {
        .sd-athrb-top {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          p {
            font-size: 18px;
            text-align: justify;
            .general-flex {
              display: flex;
              flex-direction: column;
              div {
                margin-bottom: 20px;
                h3 {
                  text-align: center;
                  margin-bottom: 0px !important;
                }
              }
            }
            .trust-board-wrapper {
              padding-bottom: 10px;
              .flexed {
                display: flex;
                flex-wrap: wrap;
                margin-top: 50px;
                justify-content: space-between;
                .trust-board {
                  text-align: center;
                  width: 50% !important;
                  margin-bottom: 20px;
                  min-width: 175px;
                }
              }
            }
          }
          .sd-imgs {
            width: 200px;
            height: 200px;
            margin: 0px 0px 20px 0px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 732px) and (min-width: 600px) {
  .sd-about-temple-history {
    flex-direction: column;
    align-items: center;
    .sd-about-temple-history-left {
      position: initial;

      width: 80%;
    }
    .sd-about-temple-history-right {
      padding-top: 30px;
      width: 100%;
    }
  }
}
@media screen and (max-width: 600px) and (min-width: 420px) {
  .sd-about-one {
    padding: 30px 30px 0px 30px;

    .sd-about-one-top {
      padding: 0px;
      h3 {
        font-size: 20px;
        padding: 30px 0px;
      }
      p {
        font-size: 18px;
      }
    }
    .sd-about-one-bottom {
      .sd-j {
        img {
          height: 360px;
        }
      }
      .sd-about-one-bottom-top {
        h1 {
          text-align: center;
        }
      }
      .sd-about-one-bottom-bottom {
        flex-wrap: wrap;
        justify-content: center;
        .sd-about-seva-div {
          width: 360px;

          h5 {
            padding-bottom: 25px;
          }
          .sd-timings {
            width: 360px;
          }
          div {
            .sd-free {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .sd-about-temple-history {
    padding: 26px 14px;
    margin-bottom: 10px;

    flex-direction: column;
    align-items: center;
    .sd-about-temple-history-left {
      width: 100%;
      position: initial;
    }
    .sd-about-temple-history-right {
      padding-top: 30px;
      width: 100%;
      .sd-about-temple-history-right-top {
        h1 {
          text-align: center !important;
        }
      }
      .sd-about-temple-history-right-bottom {
        padding: 0px;
        .sd-athrb-top {
          display: flex;
          overflow: hidden;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            margin-bottom: 20px;
          }
          p {
            font-size: 18px;
            text-align: center;
          }
          .trust-board-wrapper {
            padding-bottom: 10px;
            .profile-pic {
              img {
                width: 190px;
              }
            }
            .flexed {
              display: flex;
              flex-wrap: wrap;
              margin-top: 50px;
              justify-content: space-between;
              .trust-board {
                text-align: center;
                margin-bottom: 20px;
                min-width: 175px;
              }
            }
          }
          .sd-imgs {
            width: 200px;
            height: 200px;
            margin: 0px 0px 20px 0px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 420px) and (min-width: 320px) {
  .sd-about-one {
    padding: 30px 30px 0px 30px;

    .sd-about-one-top {
      padding: 0px;
      h3 {
        font-size: 20px;
        padding: 30px 0px;
      }
      p {
        font-size: 18px;
      }
    }
    .sd-about-one-bottom {
      .sd-j {
        img {
          height: 260px;
        }
      }
      .sd-about-one-bottom-top {
        h1 {
          text-align: center;
        }
        .sd-top-image {
          width: 100px;
          img {
            width: 100px;
          }
        }
      }
      .sd-about-one-bottom-bottom {
        flex-wrap: wrap;
        justify-content: center;
        .sd-about-seva-div {
          width: 244px;

          h5 {
            padding-bottom: 25px;
          }
          .sd-timings {
            width: 244px;
          }
          div {
            .sd-free {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .sd-about-temple-history {
    flex-direction: column;
    align-items: center;
    padding: 26px 20px;
    margin-bottom: 10px;
    .sd-about-temple-history-left {
      width: 100%;
      position: initial;

      ol {
        li {
          font-size: 16px;
        }
      }
    }
    .sd-about-temple-history-right {
      padding-top: 15px;
      width: 100%;
      .sd-about-temple-history-right-top {
        padding: 0px 20px 15px 15px;
        h1 {
          text-align: center;
        }
      }
      .sd-about-temple-history-right-bottom {
        padding: 0px;
        .sd-athrb-top {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          img {
            width: 100%;
            margin-bottom: 20px;
          }
          p {
            font-size: 14px;
            text-align: center;
          }
          .trust-board-wrapper {
            padding-bottom: 10px;
            .profile-pic {
              img {
                width: 190px;
              }
            }
            .flexed {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              margin-top: 50px;
              justify-content: center !important;
              .trust-board {
                width: 100% !important;
                text-align: center;
                margin-bottom: 20px;
                max-width: 175px;
              }
            }
          }
          .sd-imgs {
            width: 200px;
            height: 200px;
            margin: 0px 0px 20px 0px;
          }
        }
      }
    }
  }
}
