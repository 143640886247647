@use 'variables';

.sd-footer-main {
  background-color: variables.$footerTopBackground;
  width: 100% !important;
  font-family: "Proxima Nova Alt", sans-serif;

  .sd-footer-top {
    background-color: #efefef;
    height: 100px;
    background-color: variables.$footerTopBackground;
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    .sd-footer-top-left {
      width: 50%;
      display: flex;
      align-self: center;
      align-items: center;
      padding-left: 30px;
      padding-right: 30px;
      p {
        height: 50%;
        width: 35%;
        margin: 0px;
        color: #181818;
        font-size: 20px;
        line-height: normal;
        font-weight: 400;
        display: flex;
        justify-content: space-evenly;
      }
      div {
        height: 50%;
        width: 65%;

        display: flex;
        justify-content: center;
        img {
          height: 45px;
        }
      }
    }
    .sd-footer-top-right {
      width: 50%;
      align-self: center;
      align-items: center;
      border-right: 1px solid #ffffff4d;
      display: flex;
      padding-left: 30px;
      padding-right: 30px;
      p {
        width: 40%;
        height: 50%;
        margin: 0px;
        color: #181818;
        font-size: 21px;
        font-weight: 400;
        display: flex;
        justify-content: space-evenly;
        line-height: normal;
      }

      .sd-sub {
        display: flex;
        // justify-content: space-evenly;
        flex-direction: column;
        height: 75%;
        width: 60%;
        align-items: center;

        .subscribe {
          background-color: #ffffff !important;
          height: 42px;
          display: flex;
          justify-content: space-around;
          width: 100%;
          position: relative;
          border-radius: 10px;

          h4 {
            color: rgba(255, 0, 0, 0.753);
            font-size: 16px;
            font-weight: 400;
            margin-top: 5px;
            position: absolute;
            bottom: -20px;
            right: 0px;
          }
          input {
            height: 100%;
            outline: none;
            border: none;
            width: 75%;

            background-color: #ffffff !important;
            &::placeholder {
              font-size: 19px;
              font-weight: 400;
              color: #181818;
            }
          }
          img {
            width: 22px;
            align-self: center;
          }
        }
        .sd-red {
          border: 2px solid red;
        }
        .sd-orange {
          border: 2px solid #991403;
        }
      }
    }
  }
  .sd-footer-middle {
    background-color: variables.$footerMiddleBackground !important;
    height: 500px;
    width: 100%;
    .sd-footer-middle-f {
      background-color: variables.$footerMiddleBackground !important;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      position: relative;
      .sd-container-r {
        background-color: variables.$footerMiddleBackground !important;
        padding: 40px 60px 20px 60px;
        padding: 0px 60px 60px 60px;
        .row {
          display: flex;
          justify-content: space-between;
          text-align: initial;
          background-color: variables.$footerMiddleBackground;
          margin: 0px;
          .sd-list-body {
            // width: 16.66%;
            padding: 5px;
            h5 {
              font-size: 18px;
              color: #ffffff;
              margin-bottom: 13px !important;

              font-weight: 600;
            }
            ul {
              li {
                padding: 2.5px 1px;
                color: #ffffff;

                a {
                  font-size: 15px;
                  font-weight: 400;
                  color: #d1d1d1;
                  padding-bottom: 3px;
                  text-decoration: none;
                  cursor: pointer;
                  &:hover {
                    color: #ffffff;
                    font-weight: 600;
                  }
                }
              }
            }
          }
          .w-16 {
            width: 16%;
          }
          .w-18 {
            width: 19%;
            padding-right: 20px;
          }
          .w-15 {
            width: 14%;
          }
        }
      }
      .sd-additional-footer {
        // border-bottom: 1px solid #c0c3c7;

        width: 93%;
        height: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-self: center;
        align-items: center;
        background-color: variables.$footerMiddleBackground;
        .top {
          width: 100%;
          height: 70%;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: variables.$footerMiddleBackground;
          padding-bottom: 50px;
          p {
            margin: 0px;
            margin-left: 0px;
            font-size: 15px;
            color: #ffffff;
            text-align: left;
            i {
              margin-right: 13px;
            }
            img {
              height: 15px;
              margin-right: 10px;
              filter: brightness(3);
            }
          }
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          width: 100%;
          .left {
            width: 68%;
            height: 100%;
            text-align: left;
            display: flex;
            justify-content: flex-start;
            p {
              margin: 0px;
              font-size: 15px;
              color: #ffffff;
              padding: 0px 15px;
              border-right: 1px solid #b13829;
              &:last-child {
                border: none;
              }
              &:first-child {
                padding-left: 0px;
              }
              img {
                height: 13px;
                margin-right: 10px;
                filter: brightness(3);
              }
              .sep {
                height: 9px;
              }
            }
          }

          .right {
            width: 30%;
            height: 70%;
            align-self: center;
            display: flex;
            margin-right: 30px;
            justify-content: flex-end;
            .left {
              width: auto;
              p {
                font-weight: 600;
                color: #ffffff;
                span {
                  background-color: #ffffff;
                  color: #181818;
                  margin-left: 8px;
                  padding: 2px 5px;
                  font-weight: 800;
                  border-radius: 4px;
                  letter-spacing: 0.7px;
                  font-size: 18px;
                }
              }
            }
            .right {
              width: auto;

              p {
                font-weight: 600;
                color: #ffffff;
                margin: 0px;
                span {
                  margin-left: 8px;
                  padding: 2px 5px;
                  background-color: #FE6E00;
                  color: white;
                  font-weight: 700;
                  border-radius: 4px;
                  letter-spacing: 0.7px;
                }
              }
            }
          }
        }

        .sd-border-g {
          border-right: 1px solid #dcdcdc;
          border-left: 1px solid #dcdcdc;
        }
        .grey {
          color: #7f7f7f;
        }
      }
    }
  }
  .sd-footer-bottom {
    height: 120px;
    background-color: variables.$footerBottomBackground;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      background-color: variables.$footerBottomBackground;
    }
  }
  .sd-footer-tag {
    height: 60px;
    padding: 0px 60px;
    display: flex;
    align-items: center;
    background-color: variables.$footerBottomBackground;
    justify-content: space-around;
    .left {
      width: 35%;
      display: flex;
      text-align: left;

      p {
        color: #d1d1d1;
        margin-bottom: 0px;
      }
      img{
        height: 18px;
        margin-right: 3px;
      }
    }
    .middle {
      width: 50%;
      height: 25%;
      justify-content: center;
      display: flex;
      align-items: center;
      p {
        margin: 0px;
        font-size: 13px;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
      }
      img {
        height: 30px;
        width: 30px;
        filter: brightness(-1);
        padding: 5px;
        cursor: pointer;
      }
      .special {
        background-color: white;
        color: #d1d1d1;
        padding: 0vw;
        font-size: 18px;
      }
      .border-r {
        border-right: 1px solid #c0c0c0;
      }
      .color {
        color: #ffffff;
      }
    }
    .right {
      width: 40%;
      text-align: right;
      font-size: 13px;
      color: #ffffff;

      cursor: pointer;
      span {
        color: #ffffff;
        font-weight: 600;
        margin-left: 5px;
      }
    }
  }
}
.callout {
  position: fixed;
  bottom: 35px;
  right: 20px;
  z-index: 1;
  margin-left: 20px;
  max-width: 300px;
  width: 250px;
  animation: slide 1s;
  box-shadow: 1px 1px 100px 41px;
}

.callout-header {
  padding: 25px 15px;
  background: #991403;
  font-size: 30px;
  color: white;
}

.callout-container {
  padding: 20px;
  background-color: #ccc;
  color: #181818;
  button {
    background-color: #991403;
    color: white;
    padding: 6px 20px;
    border-radius: 3px;
    font-size: 16px;
    border: none;
  }
  p {
    font-size: 20px;
    color: #181818;
  }
}

.closebtn {
  position: absolute;
  top: 5px;
  right: 15px;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.closebtn:hover {
  color: lightgrey;
}
@keyframes slide {
  0% {
    // opacity: 0;
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@media screen and (min-width: 1500px) {
  .sd-footer-main {
    .sd-footer-top {
    }
    .sd-footer-bottom {
      height: 150px;
      img {
        height: 100%;
      }
    }
    .sd-footer-middle {
    }
    .sd-footer-tag {
      .left {
        width: 20%;
      }
      .middle {
        width: 55%;
      }
      .right {
        width: 25%;
      }
    }
  }
}
@media screen and (max-width: 1500px) and (min-width: 1300px) {
  .sd-footer-main {
    .sd-footer-tag {
      .left {
        width: 20%;
      }
      .middle {
        width: 55%;
      }
      .right {
        width: 25%;
      }
    }
  }
}
@media screen and (max-width: 1300px) and (min-width: 900px) {
  .sd-footer-main {
    height: 720px;
    padding-top: 0px;
    .sd-footer-top {
      height: 120px;
      .sd-footer-top-left {
        p {
          font-size: 17px;
        }
        div {
          img {
            width: 45%;
          }
        }
      }
      .sd-footer-top-right {
        p {
          font-size: 17px;
        }
        .sd-sub {
          .subscribe {
            input {
              width: 70%;
              font-size: 13px;
              &::placeholder {
                font-size: 13px;
              }
            }
            h4 {
              font-size: 14px;
            }
            img {
            }
          }
        }
      }
    }
    .sd-footer-middle {
      height: 570px;
      .sd-footer-middle-f {
        .sd-container-r {
          padding: 30px;

          .row {
            .sd-list-body {
              // width: 20%;
              h5 {
                font-size: 18px;
              }
              ul {
                li {
                  width: 80%;
                  a {
                    font-size: 14px;
                    color: white;
                    padding-bottom: 3px;
                  }
                }
              }
            }
          }
        }
        .sd-additional-footer {
          height: 100px;
          .top {
            p {
              font-size: 14px;
            }
          }
          .bottom {
            .left {
              flex-wrap: wrap;
              p {
                font-size: 14px;
                &:last-child {
                  padding-left: 0px;
                }
              }
            }
            .right {
              .left {
                p {
                  font-size: 14px;

                  span {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .sd-footer-bottom {
      height: 100px;
    }
    .sd-footer-tag {
      // height: ;
      padding-left: 40px;
      padding-right: 40px;

      .left {
        width: 10%;
        p {
          font-size: 12px;
        }
        img {
          height: 25px;
          width: 25px;
          padding: 4px;
        }
      }
      .middle {
        width: 70%;
        justify-content: center;
        img {
          height: 27px;
          width: 27px;
        }
        p {
          font-size: 12px;
        }
      }
      .right {
        width: 20%;
        img {
          height: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) and (min-width: 650px) {
  .sd-footer-main {
    height: 970px;
    .sd-footer-top {
      height: 120px;
      .sd-footer-top-left {
        display: flex;
        flex-direction: column;
        p {
          width: 100%;
          font-size: 15px;
          align-items: center;
          margin-bottom: 15px;
        }
        div {
          width: 100%;

          img {
            // height: 75%;
            width: 50%;
          }
        }
      }
      .sd-footer-top-right {
        display: flex;
        flex-direction: column;
        p {
          width: 100%;

          font-size: 15px;
          align-items: center;
          margin-bottom: 15px;
        }
        .sd-sub {
          width: 100%;

          .subscribe {
            input {
              width: 60%;
              &::placeholder {
                font-size: 14px;
              }
            }
            h4 {
              font-size: 12px;
            }
            img {
              width: 9%;
            }
          }
        }
      }
    }
    .sd-footer-middle {
      height: 860px;
      padding-top: 20px;

      .sd-footer-middle-f {
        height: 780px;
        justify-content: flex-start;

        .sd-container-r {
          width: 100%;
          padding: 20px 20px 20px 20px;
          // padding-top: 20px;
          padding-bottom: 0px;
          height: 75%;
          display: flex;
          flex-wrap: wrap;
          .row {
            justify-content: flex-start !important;
            flex-wrap: wrap;
            .sd-list-body {
              width: 33.33%;
              padding-left: 40px;
              padding-bottom: 10px;
              h5 {
                font-size: 17px;
              }
              ul {
                li {
                  a {
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
        .sd-additional-footer {
          height: 270px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .top {
            padding-bottom: 10px;
            display: flex;
            flex-wrap: wrap;
            padding-left: 40px;
          }
          .bottom {
            display: flex;
            flex-direction: column !important;
            .left {
              width: 100%;
              padding-left: 40px;
              flex-wrap: wrap;
              p {
                &:last-child {
                  padding-left: 0px !important;
                }
              }
            }
            .right {
              width: 100%;
              justify-content: flex-start;
              padding-left: 15px;
              padding-top: 30px;
            }
          }
        }
      }
    }
    .sd-footer-bottom {
      height: 80px;
    }
    .sd-footer-tag {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100px;
      width: 100%;
      padding: 0px;

      .left {
        width: 100%;
        justify-content: center;
        p {
          font-size: 10px;
        }
      }
      .middle {
        width: 100%;
        justify-content: center;
        p {
          font-size: 10px;
        }
      }
      .right {
        width: 100%;
        text-align: center;
        font-size: 10px;
      }
    }
  }
}

@media screen and (max-width: 650px) and (min-width: 350px) {
  .sd-footer-main {
    height: 330px;
    .sd-footer-top {
      height: 240px;

      display: flex;
      flex-direction: column;
      .sd-footer-top-left {
        margin-top: -15px;
        width: 100%;
        height: 50%;
        flex-direction: column;

        border-right: none !important;
        padding: 10px 15px;
        margin-bottom: 10px;
        p {
          width: 100%;
          height: 50%;
          font-size: 15px;
          align-items: center;
        }
        div {
          padding: 5px;
          width: 100% !important;
          &:last-child {
            margin-left: 10px;
          }
          img {
            width: auto;
            max-width: 150px;
            margin-left: 0px !important;
            // width: 60%;
            height: auto;
          }
        }
      }
      .sd-footer-top-right {
        width: 90%;
        height: 50%;
        margin-bottom: 10px;

        padding: 10px 24px;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid white;
        border-right: none !important;

        p {
          width: 100%;
          height: 50%;
          font-size: 15px;
          align-items: center;
        }
        div {
          height: 50%;
          .subscribe {
            width: 315px;
            height: 60%;
            input {
              width: 60%;
              font-size: 15px;
              &::placeholder {
                font-size: 15px;
              }
            }
            img {
              width: 7.5%;
            }
          }
        }
        .sd-sub {
          height: auto;
          width: 100%;

          img {
            height: 18px;
            margin-left: 5px;
          }
          .subscribe {
            height: 35px;
            padding: 0px;
          }
          input {
            font-size: 14px;
            &::placeholder {
              font-size: 14px !important ;
            }
          }
        }
      }
    }
    .sd-footer-middle {
      height: 1150px;
      display: none;
      .sd-footer-middle-f {
        justify-content: flex-start;
        .sd-container-r {
          width: 100%;
          background-color: #efefef !important;
          padding: 20px;
          padding-top: 40px;
          padding-bottom: 60px;
          height: 75%;
          .row {
            justify-content: flex-start;
            flex-wrap: wrap;
            .sd-list-body {
              width: 50%;
              padding-left: 25px;
              padding-bottom: 20px;
              h5 {
                font-size: 17px;
              }
              ul {
                li {
                  a {
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
        .sd-additional-footer {
          height: 125px;
          // display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          // flex-direction: column;
          .left {
            width: 100%;
            border-bottom: 2px solid #dcdcdc;
            border-right: none;
            padding: 10px;
            padding-left: 15px;
            justify-content: space-evenly;

            p {
              font-size: 11px;
              img {
                height: 18px;
              }
              i {
                margin-right: 16px;
              }
            }
          }
          .middle {
            width: 100%;
            padding: 10px;
            padding-left: 15px;
            border-left: none;
            border-right: none;
            border-bottom: 2px solid #dcdcdc;
            justify-content: space-evenly;

            p {
              font-size: 12px;
              img {
                height: 18px;
              }
              .sep {
                height: 12px;
              }
            }
          }
          .right {
            width: 100%;
            // border-top: 2px solid #dcdcdc;
            border-bottom: 2px solid #dcdcdc;
            padding: 10px;
            padding-left: 15px;
            justify-content: space-evenly;
            p {
              font-size: 12px;

              img {
                height: 16px;
              }
            }
          }
        }
      }
    }
    .sd-footer-bottom {
      height: 40px;
      display: none;
    }
    .sd-footer-tag {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100px;
      width: 100%;
      padding: 0px;
      .left {
        justify-content: center;
        margin-left: 0px;

        width: 100%;
        p {
          font-size: 10px;
          img {
            // font-size: 10px;
            height: 20px;
            width: 20px;
            // padding: 10px;
            // font-size: 10px;
          }
        }

        img {
          height: 30px;
          width: 30px;
        }
      }
      .middle {
        width: 100%;
        flex-wrap: wrap;
        p {
          font-size: 10px;
        }
      }
      .right {
        margin-left: 0px;
        text-align: center;
        width: 100%;
        font-size: 10px;
        img {
          height: 20px;
          width: 60px;
        }
      }
    }
  }
}
@media screen and (max-width: 350px) {
  .sd-footer-main {
    height: 340px;
    .sd-footer-top {
      height: 240px;

      display: flex;
      flex-direction: column;
      .sd-footer-top-left {
        margin-top: -15px;
        width: 100%;
        height: 50%;
        flex-direction: column;

        border-right: none !important;
        padding: 10px 15px;
        margin-bottom: 10px;
        p {
          width: 100%;
          height: 50%;
          font-size: 15px;
          align-items: center;
        }
        div {
          padding: 5px;
          width: 100% !important;
          margin-left: 0px !important;
          &:last-child {
            margin-left: 10px;
          }
          img {
            // width: 50%;
            margin-left: 0px !important;
            // width: 60%;
            height: auto;
            width: auto;
            max-width: 120px;
          }
        }
      }
      .sd-footer-top-right {
        width: 90%;
        height: 50%;
        margin-bottom: 10px;

        padding: 10px 0px;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid white;
        border-right: none !important;

        p {
          width: 100%;
          height: 50%;
          font-size: 15px;
          align-items: center;
        }
        div {
          height: 50%;
          .subscribe {
            width: 315px;
            height: 60%;
            input {
              width: 60%;
              font-size: 15px;
              &::placeholder {
                font-size: 15px;
              }
            }
            img {
              width: 7.5%;
            }
          }
        }
        .sd-sub {
          height: auto;
          width: 290px;

          img {
            height: 18px;
            margin-left: 5px;
          }
          .subscribe {
            height: 35px;
            padding: 0px;
          }
          input {
            font-size: 14px;
            &::placeholder {
              font-size: 14px !important ;
            }
          }
        }
      }
    }
    .sd-footer-middle {
      display: none;
      height: 1200px;
      .sd-footer-middle-f {
        justify-content: flex-start;
        .sd-container-r {
          width: 100%;
          background-color: #efefef !important;
          padding: 20px;
          padding-top: 40px;
          padding-bottom: 60px;
          height: 75%;
          .row {
            justify-content: flex-start;
            flex-wrap: wrap;
            .sd-list-body {
              width: 50%;
              padding-left: 25px;
              padding-bottom: 10px;
              h5 {
                font-size: 17px;
              }
              ul {
                li {
                  a {
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
        .sd-additional-footer {
          height: 125px;
          // display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          // flex-direction: column;
          .left {
            width: 100%;
            border-bottom: 2px solid #dcdcdc;
            border-right: none;
            padding: 10px;
            padding-left: 15px;
            justify-content: space-evenly;

            p {
              font-size: 11px;
              img {
                height: 18px;
              }
              i {
                margin-right: 16px;
              }
            }
          }
          .middle {
            width: 100%;
            padding: 10px;
            padding-left: 15px;
            border-left: none;
            border-right: none;
            border-bottom: 2px solid #dcdcdc;
            justify-content: space-evenly;

            p {
              font-size: 12px;
              img {
                height: 18px;
              }
              .sep {
                height: 12px;
              }
            }
          }
          .right {
            width: 100%;
            // border-top: 2px solid #dcdcdc;
            border-bottom: 2px solid #dcdcdc;
            padding: 10px;
            padding-left: 15px;
            justify-content: space-evenly;
            p {
              font-size: 12px;

              img {
                height: 16px;
              }
            }
          }
        }
      }
    }
    .sd-footer-bottom {
      height: 40px;
      display: none;
    }
    .sd-footer-tag {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 110px;
      width: 100%;
      padding: 0px;
      .left {
        justify-content: center;
        margin-left: 0px;

        width: 100%;
        p {
          font-size: 9.5px;
          img {
            // font-size: 10px;
            height: 20px;
            width: 20px;
            // padding: 10px;
            // font-size: 10px;
          }
        }

        img {
          height: 30px;
          width: 30px;
        }
      }
      .middle {
        width: 100%;
        flex-wrap: wrap;
        p {
          font-size: 9.5px;
        }
      }
      .right {
        margin-left: 0px;
        text-align: center;
        width: 100%;
        font-size: 9.5px;
        img {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}
