.sd-d-bottom {
  width: 100vw;
  height: 100%;
  display: flex;
  .d-left {
    width: 25%;
    border-right: 2px solid #efefef;
    .d-l {
    }
  }
  .sd-d-middle {
    width: 50%;
    border-right: 2px solid #efefef;

    .d-m {
    }
  }
  .sd-d-right {
    width: 25%;
    .d-r {
    }
  }
}
.select-div {
  color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: #eaeaea !important;
  z-index: 1;
  .constant-top {
    display: flex;
    justify-content: space-evenly;
    border-radius: 4px;
    // height: 6vh;
    padding: 5px;
    width: 100%;
    height: 100%;
    .sd-input-image {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 30%;
      position: relative;

      input {
        padding-left: 10px;
        outline: none;
        box-shadow: none;
        height: 100%;
        border: none;
        background-color: transparent !important;
        color: white;
        font-size: 17px;
        margin-bottom: 2px;
        line-height: 44px;
        cursor: pointer;
        width: 90%;
      }
      img {
        position: absolute;
        filter: invert(1);
        width: 30px;
        padding-right: 10px;
        right: 0px;
        top: 12px;
      }
      .sd-image-invert {
        width: 30px;
        position: absolute;

        transform: rotate(180deg);
        padding-left: 10px;
        padding-right: 0px;
        right: 0px;
        top: 12px;
      }
    }

    .sd-white {
      background-color: white;
    }
    .w-10 {
      width: 10% !important;
    }
    .h-34 {
      height: 34px;
      border: 4px;
    }
    .sd-buttonArray {
      display: flex;
      flex-direction: column;
      width: 70%;
      cursor: pointer;
    }
    .sd-buttonArray-pub {
      min-height: 40px;
      display: flex;
      flex-wrap: wrap;
      // overflow-x: scroll;

      width: 90%;
      cursor: pointer;
    }
    .horizon-scroll-div {
      align-self: center;
      display: flex;
      width: 38%;
      overflow-x: hidden;
      max-width: 38%;
    }
  }
  .sd-white {
    background-color: white;
  }
  .sd-voilet-border {
    border: 1px solid #2f3293;
  }
  .option-div {
    width: 100%;
    padding-bottom: 10px;
    z-index: 1;
    color: #181818;
    .option-class {
      cursor: pointer;
      padding: 8px;
      padding-left: 20px;
      width: 100%;
      display: flex;
      font-size: 15px;
      font-weight: 400;
      justify-content: flex-start;
      color: #181818;
      background-color: #eaeaea;
      &:hover {
        background-color: #dee2fb !important;
      }
    }
  }
  .option-div-pub {
    width: 100%;
    border: 1px solid #bcbcbc;
    padding: 3px;
    z-index: 1;
    position: absolute;
    color: #181818;
    .option-class {
      cursor: pointer;
      padding: 5px;
      // padding-left: 2px;
      width: 100%;
      display: flex;
      font-size: 15px;
      font-weight: 400;
      justify-content: flex-start;
      color: #181818;
      background-color: #ffffff;
      &:hover {
        background-color: #dee2fb !important;
      }
    }
  }
}
.button-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #dee2fb;
  color: #181818;
  padding: 3px;
  height: 100%;
  border-radius: 4px;
  margin-bottom: 2px;
  p {
    color: #181818;
    margin-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
  h3 {
    font-size: 15px;
    margin: 0px;
    color: #991403;
    margin-left: 5px;

    cursor: pointer;
    padding-right: 5px;
  }
  .button-array-button {
    background-color: #1d2024;
    display: flex;
    margin-right: 6px;
    align-items: center;
    white-space: nowrap;

    width: 6vw;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #65656a;
    color: white;
    .button-array-span {
      font-size: smaller;
      color: white;
      padding: 3px;
      text-align: center;
    }
  }
  .button-array-image {
    position: initial;
    display: flex;
    width: 9px;
    margin-left: -16px;
    margin-right: 2px;
    margin-bottom: 16px;
    /* filter: invert(1); */
    z-index: 10;
    cursor: pointer;
  }
}
.button-div-pub {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #dee2fb;
  color: #181818;
  white-space: nowrap;
  padding: 3px;
  width: 170px;
  height: 34px;
  border-radius: 4px;
  margin-bottom: 5px;
  margin-right: 5px;
  p {
    color: #181818;
    margin-bottom: 0px;
    padding-left: 10px;
    padding-right: 5px;
  }
  h3 {
    font-size: 15px;
    margin: 0px;
    color: #991403;
    margin-left: 5px;

    cursor: pointer;
    padding-right: 5px;
  }
  .button-array-button {
    background-color: #1d2024;
    display: flex;
    margin-right: 6px;
    align-items: center;
    white-space: nowrap;

    width: 2vw;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #65656a;
    color: white;
    .button-array-span {
      font-size: smaller;
      color: white;
      padding: 3px;
      text-align: center;
    }
  }
  .button-array-image {
    position: initial;
    display: flex;
    width: 9px;
    margin-left: -16px;
    margin-right: 2px;
    margin-bottom: 16px;
    /* filter: invert(1); */
    z-index: 10;
    cursor: pointer;
  }
}
.mr-3 {
  margin-left: 3px;
  margin-right: 3px;
}
@media (max-width: 650px) {
  .sd-buttonArray {
    width: 100%;
  }
  .option-div {
    .option-class {
      display: block !important;
    }
  }
}
